'use strict';
import 'typeface-open-sans';

import './css/app.css';

// quick polyfill to add `forEach` support to dom methods for browsers that don't yet support it
if (window.NodeList && !NodeList.prototype.forEach) {
	NodeList.prototype.forEach = Array.prototype.forEach;
}
if (window.HTMLCollection && !window.HTMLCollection.prototype.forEach) {
	HTMLCollection.prototype.forEach = Array.prototype.forEach;
}

// setup function to run on load, to handle filtering
const onLoad = function() {
	const body = document.body;
	body.classList.remove('javascriptDisabled');
	body.classList.add('javascriptEnabled');

	const selectElements = document.querySelectorAll('input.click-select');
	if (selectElements) {
		for (const element of selectElements) {
			element.addEventListener('click', function() {
				element.select();
			});
		}
	}
};

// handle async JavaScript - this file shouldn't ever load before `DOMContentLoaded` in modern browsers as it's loaded async, but can in older browsers, or race-conditions
if (document.readyState !== 'loading') {
	onLoad();
} else {
	document.addEventListener('DOMContentLoaded', onLoad);
}
